import React, { useState, useEffect } from 'react'
import { Header, Sidebar, ConnectWallet } from "../components";
import axios from "axios";
import { useWallet } from '@solana/wallet-adapter-react';
import { useDispatch, useSelector } from 'react-redux'
import { HOST, configuration } from '../utils/constants'
import { fetchWallet } from '../redux/userActions'
import { DarkModeProvider } from '../context/DarkModeContext';
import { ToastContainer, toast } from 'react-toastify';
import { Label, Modal, Spinner, Table, TextInput } from 'flowbite-react';
import { shortenAddress } from '../utils/shortenAddress';
import { IoMdLock } from "react-icons/io";


const MyWallet = () => {
  const { publicKey, connected } = useWallet();
  const [sendingTransac, setSendingTransac] = useState(false)
  const dispatch = useDispatch()
  const { userInfo } = useSelector(
    (state) => state.user
  )
  const [openModal, setOpenModal] = useState(false)
  const [amountToSend, setamountToSend] = useState(0)
  const [myraids, setmyraids] = useState(null)
  const [clmed, setclmed] = useState(false)
  const [withdrawstatus, setwithdrawStatus] = useState(null)

  function copyToClip() {
    navigator.clipboard.writeText(userInfo?.address);
    toast.info("copied", {
      position: toast.POSITION.TOP_CENTER,
      theme: "dark",
      autoClose: 800
    });
  }

  useEffect(() => {
    setSendingTransac(false)

  }, [])


  // Example usage:

  async function sendBoop() {
    if (amountToSend < 30) {
      alert("minimum amount of withdrawal is 30")
    } else if (amountToSend > userInfo?.boopToClaim) {

      alert("You can't withraw more than " + userInfo?.boopToClaim)

    } else {
      setSendingTransac(true)

      const receiverPublicKey = publicKey?.toBase58();

      let dataToSend = {
        "amount": parseFloat(amountToSend),
        "address": receiverPublicKey
      }
      try {
        const response = await axios.post(`${HOST}/auth/withdraw/signBooptx`, dataToSend, configuration)
        const resData = await response.data


        if (resData.status === 0) {
          // console.log(resData)
          toast.warning(resData.message, {
            position: toast.POSITION.TOP_CENTER,
            theme: "dark",
            autoClose: 2000
          });


          setSendingTransac(false)
          setOpenModal(false)
        } else {

          toast.success(resData.message, {
            position: toast.POSITION.TOP_CENTER,
            theme: "dark",
            autoClose: 2000
          });
          setSendingTransac(false)
          setOpenModal(false)
          dispatch(fetchWallet(userInfo?.address))
          setclmed(prevState => !prevState)
        }

      } catch (error) {
        console.log(error)
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_CENTER,
          theme: "dark",
          autoClose: 2000
        });
        setSendingTransac(false)
      }
    }

  }

  const handleInputChange = (event) => {
    const newValue = event.target.value;

    if (parseFloat(newValue) > userInfo?.boopToClaim) {
      alert(`Withdrawal amount can't be greate than ${userInfo?.boopToClaim}`)
      // setamountToSend('');

      return;
    }

    setamountToSend(newValue);

  };

  useEffect(() => {
    async function checkWithdrawalStatus() {
      try {
        const response = await axios.get(`${HOST}/auth/withdraw/checkWithdrawalStatus?type=raiders`, configuration)
        const dataRes = await response.data

        setwithdrawStatus(dataRes)

      } catch (error) {
        console.log(error)
        setwithdrawStatus(null)
      }
    }

    checkWithdrawalStatus()
  }, [])





  useEffect(() => {
    async function getRaidss() {
      try {
        const response = await axios.get(`${HOST}/auth/withdraw/getWithHistory?usrId=${publicKey?.toBase58()}`, configuration)
        const dataRes = await response.data

        setmyraids(dataRes.data)


      } catch (error) {
        console.log(error)
        setmyraids([])
      }
    }

    if (publicKey) {
      getRaidss()
    }

  }, [connected, clmed])



  return (
    <DarkModeProvider>
      <Header />
      <ToastContainer />

      <Modal dismissible="true" show={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Header>Withdraw</Modal.Header>
        <Modal.Body>

          <form>
            <div className='mb-5'>
              <Label value='Address' />
              <TextInput defaultValue={userInfo?.address} className='mt-1' readOnly disabled />
            </div>

            <div className='mb-5'>
              <Label value='Amount to withdraw' />
              <TextInput type='number'
                value={amountToSend}
                onChange={handleInputChange}
                className='mt-1 theInput' />

              <div className='flex justify-between items-center mt-2'>
                <p className='text-white text-sm fontBold'>Balance: {userInfo?.boopToClaim}</p>
                {/* <button className='textPrimary text-sm font-bold' onClick={setMax} type='button'>Max</button> */}
              </div>
            </div>

            {sendingTransac ? (
              <div className='flex justify-center'>
                <Spinner size="lg" />
              </div>
            ) : (
              <button className='button w-full' type='button' onClick={sendBoop}>Withdraw</button>
            )}

          </form>

        </Modal.Body>

      </Modal>

      <section className="h-screen flex relative">
        <Sidebar />
        <div className='h-full lg:pl-[320px] w-full mt-24 px-[3%] dark:text-gray-300'>
          <h1 className='text-xl fontBold mb-5'>My Wallet</h1>
          {connected ? (
            <>
              <div className='profSection'>

                {/* Balance Section */}
                <div className='flex justify-between'>
                  <div className="">
                    <p className="text-white fontBold w-fit">Current Balance</p>
                    <p className="text-xl font-bold text-[#FA6002] w-fit">{userInfo?.boopToClaim} $BOOP</p>


                  </div>

                  <div>
                    <button className='button fontBold mb-2' onClick={() => setOpenModal(true)}>Withdraw</button>
                    {withdrawstatus && (

                      <>
                        {withdrawstatus.message === "locked" ? (
                          <button className='button fontBold flex items-center gap-1'><IoMdLock /> <span>Locked</span></button>
                        ) : (
                          <button className='button fontBold' onClick={() => setOpenModal(true)}>Withdraw</button>
                        )}
                      </>
                    )}

                    {/* <button className='button fontBold' onClick={sendBoop}>Withdraw</button> */}

                  </div>
                </div>

                <p className="text-white fontBold mt-4">Address</p>
                <p className="text-gray-300 text-sm cursor-pointer font-semibold" onClick={copyToClip}>{userInfo?.address}</p>

              </div>


              <section className='profSection mt-10 p-4'>
                <h1 className='text-xl text-white mb-5'>Recent Transactions</h1>

                {myraids ? (
                  <>
                    {myraids.length === 0 ? (
                      <div className='flex justify-center'>
                        <p className='text-lg'> No recents transaction</p>
                      </div>
                    ) : (
                      <>

                        <Table className='mt-4'>
                          <Table.Head className=''>
                            {["S/N", "Date", "Amount", "Address", "Balance after withdraw", "txHash"].map((withHis, i) => (
                              <Table.HeadCell key={i}>{withHis}</Table.HeadCell>
                            ))}
                          </Table.Head>

                          <Table.Body>
                            {myraids.reverse().map((mraid, i) => (
                              <Table.Row key={i}>
                                <Table.Cell className='font-bold'>{i + 1}</Table.Cell>

                                <Table.Cell className='font-bold'>{mraid.timestamp}</Table.Cell>
                                <Table.Cell className='font-bold'>{mraid.amount}</Table.Cell>
                                <Table.Cell className='font-bold'>{shortenAddress(mraid.address)}</Table.Cell>
                                <Table.Cell className='font-bold'>{mraid.balanceAfter}</Table.Cell>
                                <Table.Cell className='font-bold'><a href={mraid.solscanUrl} target='_blank' rel='noreferrer'>Tx Signature</a></Table.Cell>


                              </Table.Row>
                            ))}
                          </Table.Body>
                        </Table>

                      </>
                    )}
                  </>
                ) : (
                  <div className="flex justify-center">
                    <Spinner size="lg" />
                  </div>
                )
                }




              </section>
            </>
          ) : (
            <ConnectWallet />
          )}



        </div>



      </section>
    </DarkModeProvider>
  )
}

export default MyWallet